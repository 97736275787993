import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router, Routes, Params, RoutesRecognized, Data } from '@angular/router';
import { filter, pairwise } from 'rxjs';

import { ENVIRONMENT, Environment } from '@environment';
import { Channel, Link, PageLink } from '@usheru-hff/shared/domain';
import { ChannelService } from '@usheru-hff/shared/utils-channel';

// const FALLBACK_ROUTE = [
//   {
//     path: '',
//     pathMatch: 'full',
//     redirectTo: '/home'
//   },
//   {
//     path: 'page/home',
//     pathMatch: 'full',
//     redirectTo: '/home'
//   }
// ];

const LINK_POSITION_HEADER = 1;
const LINK_POSITION_FOOTER = 3;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private routes: Routes = [];
  private headerLinks: PageLink[] = [];
  private footerLinks: PageLink[] = [];
  private channel: Channel;
  private customWatchAtHomeRoute: EventEmitter<string> = new EventEmitter<string>();
  private originalWatchAtHomeName: string;
  private currentUrl: string;
  private currentParams: Params;
  private previousUrl: string;
  private previousParams: Params;
  private beforeAuthUrl: string;
  private beforeAuthParams: Params;

  constructor(
    private channelService: ChannelService,
    @Inject(ENVIRONMENT) private env: Environment,
    private router: Router
  ) {
    this.channel = channelService.channel;
    const hLinks = this.channel.links.filter(link => link.position == LINK_POSITION_HEADER);
    const fLinks = this.channel.links.filter(link => link.position == LINK_POSITION_FOOTER);
    const orderSorter = (l1, l2) => l1.order - l2.order;
    hLinks.sort(orderSorter);
    fLinks.sort(orderSorter);

    if (hLinks) {
      this.createPageLinks(hLinks);
      // after this step I have this.headerLinks in PageLink[] shape
      this.handleCustomWatchAtHomeLinks(this.headerLinks);
    }
    if (fLinks) {
      this.createPageLinks(fLinks);
    }
    // subscription to know previous and current url
    this.router.events
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        const previous = events[0].urlAfterRedirects;
        this.previousUrl = previous.split('?')[0];
        this.previousParams = this.stringToParams(previous.split('?')[1] || '');
        const current = events[1].urlAfterRedirects;
        this.currentUrl = current.split('?')[0];
        this.currentParams = this.stringToParams(current.split('?')[1] || '');
      });
  }

  private createPageLinks(links: Link[]) {
    links.forEach(link => {
      const url = link.url.replace('/page', '');
      const isExternal = this.isExternal(url);
      const destinationLinks = link.position == LINK_POSITION_FOOTER ? this.footerLinks : this.headerLinks;

      if (isExternal) {
        destinationLinks.push({
          name: link.name,
          link: url,
          isExternal: true,
          fragment: link?.fragment,
          visibility: link.visibility
        });
        return;
      }
      this.routes.push(...this.customPageRoutes(url));

      destinationLinks.push({
        name: link.name,
        link: url,
        isExternal: isExternal,
        fragment: link?.fragment,
        visibility: link.visibility
      });
    });
  }

  private stringToParams(value: string): Params {
    if (value.length == 0) return {};
    if (value.includes('?')) value = value.split('?')[1];
    const params = value.split('&');
    return params.reduce((acc, cur) => {
      const s = cur.split('=');
      const p = s[0];
      const v = s[1] ? s[1] : 'true';
      acc[p] = v;
      return acc;
    }, {});
  }

  private isExternal(url: string): boolean {
    return url.startsWith('http://') || url.startsWith('https://');
  }

  private customPageRoutes(customPage: string): Routes {
    let url = customPage;
    if (url.startsWith('/')) {
      url = url.substr(1);
    }
    url = url.replace('page/', '');

    return [
      {
        path: `${url}`
      }
    ];
  }

  public getRoutes(): Routes {
    return this.routes;
  }

  public getHeaderLinks(): PageLink[] {
    return this.headerLinks;
  }

  public getFooterLinks(): PageLink[] {
    return this.footerLinks;
  }

  public getPreviusUrl(): string {
    return this.previousUrl;
  }

  public getCurrentUrl(): string {
    return this.currentUrl;
  }

  public addHeaderLinks(...pageLinks: PageLink[]): void {
    this.addPageLinks(this.headerLinks, ...pageLinks);
  }

  public addFooterLinks(...pageLinks: PageLink[]): void {
    this.addPageLinks(this.footerLinks, ...pageLinks);
  }

  public addCustomWatchAtHomeRoute(tag: string) {
    this.customWatchAtHomeRoute.emit(tag);
  }

  public removeCustomWatchAtHomeRoute() {
    this.customWatchAtHomeRoute.emit('');
    if (this.originalWatchAtHomeName) {
      this.headerLinks.find(link => link.name == this.originalWatchAtHomeName).link = '/movies/watch-at-home';
    }
  }

  private addPageLinks(links: PageLink[], ...pageLinks: PageLink[]): void {
    if (pageLinks && pageLinks.length) {
      pageLinks.forEach(pageLink => {
        const index = links.findIndex(p => p.link === pageLink.link);

        if (index < 0) {
          links.unshift(pageLink);
        } else {
          links[index] = pageLink;
        }
      });
    }
  }

  public removeHeaderLinks(...pageLinks: string[]): void {
    this.removePageLinks(this.headerLinks, ...pageLinks);
  }

  public removeFooterLinks(...pageLinks: string[]): void {
    this.removePageLinks(this.footerLinks, ...pageLinks);
  }

  private removePageLinks(links: PageLink[], ...pageLinks: string[]): void {
    if (pageLinks && pageLinks.length) {
      pageLinks.forEach(pageLink => {
        const index = links.findIndex(p => p.link === pageLink || p.link === '/' + pageLink);

        if (index > -1) {
          links.splice(index, 1);
        }
      });
    }
  }

  private validatePath(path: string): string {
    if (path.indexOf('/') == 0) {
      path = path.slice(1, path.length);
    }
    return path;
  }

  private handleCustomWatchAtHomeLinks(links: PageLink[]) {
    this.customWatchAtHomeRoute.subscribe(tag => {
      const watchAtHomeLink = links.find(
        link => link.link == '/movies/watch-at-home' || link.link == 'movies/watch-at-home'
      );
      if (watchAtHomeLink) {
        this.originalWatchAtHomeName = watchAtHomeLink.name;
        watchAtHomeLink.link = '/movies/tag/' + tag || watchAtHomeLink.link;
      }
    });
  }

  public navigate(route: string, qParams?) {
    this.router.navigate([route], { queryParams: qParams });
  }

  public navigateBack() {
    this.router.navigate([this.getPreviusUrl()]);
  }

  public setBeforeAuthUrl() {
    if (!this.currentUrl?.includes('/auth/')) {
      this.beforeAuthUrl = this.currentUrl;
      this.beforeAuthParams = this.currentParams;
    }
  }

  public navigateToBeforeAuthUrl(qParams?: Params) {
    if (this.previousUrl?.includes('/auth/')) this.previousUrl = '/';
    const url = this.beforeAuthUrl || this.previousUrl || '/';
    const params = this.beforeAuthUrl ? this.beforeAuthParams : this.previousUrl ? this.previousParams : {};
    this.navigate(url, Object.assign(params || {}, qParams || {}));
  }
}

export type ExtraRouteData = {
  /* On a branded page context, it adds the ability to show an extra button to show the full movie  */
  fullVideo: boolean;
  additionalLink: string;
} & Data;
